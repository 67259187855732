<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí Study Plan Unit mục {{ study_plan_name }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogUnit"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Tạo Unit</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSortUnit"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp </span>
      </v-btn>

      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Type</th>
                    <th scope="col">Course</th>
                    <th scope="col">Length</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(unit, i) in study_plan_units">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ unit.test.title }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="unit.type === 1"
                        >
                          Lesson
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="unit.type === 2"
                        >
                          Test
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ unit.course.name.vi }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="unit.length"
                        >
                          {{ unit.length | convertSecondToHM }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editUnit(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteUnit(unit.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <StudyPlanUnitForm
      :show_dialog="dialogStudyPlanUnit"
      :type_form="type_form"
      :courses="course_tags"
      :study_plan_unit="study_plan_unit"
      :study_plan_units="study_plan_units"
      @setDialog="(e) => (this.dialogStudyPlanUnit = e)"
      @resetData="getStudyPlanUnit()"
      @resetStudyUnit="() => (this.study_plan_unit = {})"
    />

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortUnit"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortUnit = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css cursor-pointer"
                      v-for="(unit, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ unit.test.title }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="sortUnit"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSortUnit = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import StudyPlanUnitForm from "./modal/StudyPlanUnitForm";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "StudyPlanUnit",
  components: {
    StudyPlanUnitForm,
    Loading: () => import("vue-loading-overlay"),
    draggable,
  },
  data() {
    return {
      is_call_api: false,
      study_plan_units: [],
      course_tags: [],
      dialogStudyPlanUnit: false,
      type_form: null,
      dialogSortUnit: false,
      data_draggable: [],
      study_plan_name: "",
      study_plan_unit: {},
    };
  },
  computed: {
    study_plan_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllCourse();
    this.getStudyPlanUnit();
  },
  methods: {
    async getStudyPlanUnit() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/study-plan/" + this.study_plan_id + "/unit"
        );
        if (res.status === 200) {
          vm.study_plan_units = res.data.data;
          vm.study_plan_name = res.data.study_plan_name;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          vm.course_tags = response.data;
        }
      });
    },
    openDiaLogUnit() {
      this.dialogStudyPlanUnit = true;
      this.type_form = "add";
    },
    deleteUnit(unit_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete(
            "prep-app/study-plan/" + this.study_plan_id + "/unit/" + unit_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.getStudyPlanUnit();
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    openDialogSortUnit() {
      this.dialogSortUnit = true;
      this.data_draggable = JSON.parse(JSON.stringify(this.study_plan_units));
    },
    sortUnit() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let data_sort = this.data_draggable.map((item, index) => {
            return {
              unit_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/study-plan/" + this.study_plan_id + "/unit/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getStudyPlanUnit();
                vm.$toasted.success("Săp xếp thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSortUnit = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    editUnit(index) {
      this.study_plan_unit = this.study_plan_units[index];
      this.type_form = "update";
      this.dialogStudyPlanUnit = true;
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
