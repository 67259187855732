<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row>
      <v-dialog
        v-model="dialogStudyPlanUnit"
        max-width="800px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Tạo Study Plan Unit</span
              >
              <span class="headline" v-else>Sửa Study Plan Unit</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogStudyPlanUnit = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Type:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="type_input" row class="mt-0">
                        <v-radio label="Lesson" :value="type.LESSON"></v-radio>
                        <v-radio label="Test" :value="type.TEST"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Thời lượng (Giờ/phút):</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        v-model="length_input"
                        type="time"
                        format="HH:mm"
                        value-type="format"
                        placeholder="hh:mm"
                      ></date-picker>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Course:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="course_id_input"
                        :items="courses"
                        item-value="id"
                        item-text="name.vi"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Test:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="test_id_input"
                        :items="course_items"
                        item-value="test_id"
                        item-text="test_title"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditStudyPlanUnit(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditStudyPlanUnit(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogStudyPlanUnit = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";

export default {
  name: "StudyPlanUnitForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    courses: {
      type: Array,
      default: () => [],
    },
    new_offset: {
      type: Number,
      default: 0,
    },
    study_plan_unit: {},
    study_plan_units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      type_input: null,
      type: {
        LESSON: 1,
        TEST: 2,
      },
      length_input: null,
      course_id_input: null,
      course_item_id_input: null,
      test_id_input: null,
      course_items: [],
      study_plan_unit_id: null,
    };
  },
  computed: {
    dialogStudyPlanUnit: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
    study_plan_id: function () {
      return this.$route.params.id;
    },
  },
  watch: {
    course_id_input(val) {
      if (val !== null) {
        this.getCourseItemByCourseId(val);
      }
      if (val == null) {
        this.course_items = [];
      }
    },
    test_id_input(val) {
      if (val != null) {
        if (this.course_items.length > 0) {
          let course_item = this.course_items.filter(
            (e) => e.test_id === val
          )[0];
          this.course_item_id_input = course_item.id;
        }
      }
    },
    dialogStudyPlanUnit(val) {
      if (val) {
        this.setDataForm();
      } else {
        this.type_input = null;
        this.length_input = null;
        this.course_items = [];
        this.course_id_input = null;
        this.course_item_id_input = null;
        this.test_id_input = null;
        this.$emit("resetStudyUnit");
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.type_input = 1;
        this.length_input = null;
        this.course_id_input = null;
        this.course_item_id_input = null;
        this.test_id_input = null;
      } else {
        let data = JSON.parse(JSON.stringify(this.study_plan_unit));
        this.study_plan_unit_id = data.id;
        this.type_input = data.type;
        this.length_input = data.length
          ? this.$options.filters.convertSecondToHM(data.length)
          : null;
        this.course_id_input = data.course.id;
        this.course_item_id_input = data.course_item_id;
        this.test_id_input = data.test.id;
      }
    },
    async getCourseItemByCourseId(course_id) {
      let vm = this;
      this.is_call_api = true;
      await ApiService.get("prep-app/courses/" + course_id + "/list-item")
        .then(function (response) {
          if (response.status === 200) {
            vm.course_items = response.data;
            vm.is_call_api = false;
          }
        })
        .catch(function (err) {
          if (err) {
            vm.is_call_api = false;
          }
        });
    },
    validateData() {
      let flat = true;
      if (this.type_input == null) {
        this.$toasted.error("Hãy chọn type !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      // if (this.length_input === null) {
      //   this.$toasted.error('Hãy thời nhập lượng !!', { theme: "toasted-primary", position: "top-right", duration : 4000 });
      //   flat = false;
      // }
      if (this.course_id_input === null) {
        this.$toasted.error("Hãy chọn course !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.test_id_input === null) {
        this.$toasted.error("Hãy chọn less/test !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.checkExistTest()) {
        this.$toasted.error(
          "Less/test này đã được thêm trong study plan unit hiện tại!!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        flat = false;
      }

      return flat;
    },
    checkExistTest() {
      let vm = this;
      let test_ids = this.study_plan_units.map((e) => e.test.id);
      if (this.type_form === "update") {
        test_ids = test_ids.filter((e) => e !== vm.study_plan_unit.test.id);
      }
      return test_ids.includes(this.test_id_input);
    },
    btnAddOrEditStudyPlanUnit(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        type: this.type_input,
        // length: this.$options.filters.convertHMToSecond(this.length_input),
        course_id: this.course_id_input,
        test_id: this.test_id_input,
        course_item_id: this.course_item_id_input,
      };
      if (this.duration_input)
        data.length = this.$options.filters.convertHMToSecond(
          this.length_input
        );
      let vm = this;
      this.is_call_api = true;
      if (type === 1) {
        data.offset = this.new_offset;
        ApiService.post(
          "prep-app/study-plan/" + this.study_plan_id + "/unit",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogStudyPlanUnit = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put(
          "prep-app/study-plan/" +
            vm.study_plan_id +
            "/unit/" +
            this.study_plan_unit_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogStudyPlanUnit = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
